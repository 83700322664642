import session from './session'

export default {
  getRadios(patientId) {
    return session.get('api/patients/' + patientId + '/radios/')
  },
  getRadio(idRadio) {
    return session.get('api/radios/' + idRadio + '/')
  },
  deleteRadio(radio) {
    return session.delete(
      '/api/patients/' + radio.patient.id + '/radios/' + radio.id + '/',
    )
  },
  upsertRadio(radio) {
    const data = {
      doctor: radio.doctor,
      patient: radio.patient,
      radio: radio.radio,
    }
    if (radio.id) {
      return session.put(
        'api/patients/' + data.patient + '/radios/' + radio.id + '/',
        data,
      )
    } else {
      return session.post('/api/radios/', data)
    }
  },
}
